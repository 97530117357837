<app-skeleton-loader-placeholder [isLoading]="isLoading">
  @if (allActionsList?.length > 0) {
    <div class="flex flex-row gap-2">
      <app-button-list [actionList]="allActionsList" [listType]="'dropdown'"></app-button-list>
      <!-- <app-button-list *ngIf="stateMachineEventActionList?.length > 0" [actionList]="[{id:124,label:'asdadaasda',items:stateMachineEventActionList}]" [listType]="'dropdown'"></app-button-list>
      <app-button-list *ngIf="actionActionList?.length > 0" [actionList]="[{id:12,label:'blablabla',items:actionActionList}]" [listType]="'dropdown'"></app-button-list>
      <app-button-list *ngIf="fieldEditValidatorActionList?.length > 0" [actionList]="fieldEditValidatorActionList" [listType]="'dropdown'"></app-button-list> -->
    </div>
  }
</app-skeleton-loader-placeholder>
