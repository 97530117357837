@if (!!data) {
  <p
    class="p-0"
    style="
      box-sizing: border-box;
      line-height: 1.42;
      height: 100%;
      outline: none;
      overflow-y: auto;
      tab-size: 4;
      -moz-tab-size: 4;
      text-align: left;
      white-space: pre-wrap;
      word-wrap: break-word;
      word-break: break-word;
    "
    [innerHTML]="data | highlight: filter"
  ></p>
} @else {
  <!-- <p>--</p> -->
}
