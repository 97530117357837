import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FlatRule, IAction, UnsubscribeOnDestroyAdapter, isNullObj } from '@shared/classes';
import { BaseRequestControllerWithRuleService } from '@shared/services/api/custom-api-services/base-request-controller-with-rule.service';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-flat-rule-handler',
  templateUrl: './flat-rule-handler.component.html',
  styleUrls: ['./flat-rule-handler.component.scss'],
})
export class FlatRuleHandlerComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  ruleList: FlatRule[] = [];
  ruleTypes = FlatRule.RuleTypeEnum;
  stateMachineEventActionList: IAction[] = [];
  actionActionList: IAction[] = [];
  fieldEditValidatorActionList: IAction[] = [];
  allActionsList: IAction[] = [];

  public isLoading: boolean = false;
  private _config: Config = null;
  @Input() actionMap: { [x: string]: IAction } = {};
  @Input() set config(conf: Config) {
    if (conf) {
      this._config = conf;
      this.fetchRules();
    }
  }
  get config() {
    return this._config;
  }
  @Output() onSetRuleList: EventEmitter<FlatRule[]> = new EventEmitter();
  constructor(private permissionService: NgxPermissionsService) {
    super();
  }

  ngOnInit(): void {}
  fetchRules() {
    if (this.config.requestService && this.config.itemId) {
      this.isLoading = true;
      this.subs.sink = this.config.requestService.getRuleHandlers(this.config.itemId).subscribe({
        next: (res) => {
          this.ruleList = [...res];
          this.parseRuleList(this.ruleList);
          this.onSetRuleList.emit(this.ruleList);
        },
        complete: () => {
          this.isLoading = false;
        },
      });
    }
  }
  async parseRuleList(rules: FlatRule[]) {
    //@TODO: once clearer functional logic is added please make a function for each type ...etc
    this.stateMachineEventActionList = [];
    this.actionActionList = [];
    this.fieldEditValidatorActionList = [];
    // Mapping each rule to a promise representing the asynchronous permission check
    const permissionCheckPromises = rules.map(async (rule) => {
      let key = rule.ruleHandler as string; //@TODO check ruleHandler in backend why is the model repeating rule type ?
      switch (rule.ruleType) {
        case 'STATE_MACHINE_EVENT':
          if (this.actionMap[key]) {
            const isAllowed = await this.checkActionPermissions(this.actionMap[key]);
            if (isAllowed) this.stateMachineEventActionList.push(this.actionMap[key]);
          }
          break;
        case 'ACTION':
          if (this.actionMap[key]) {
            const isAllowed = await this.checkActionPermissions(this.actionMap[key]);
            if (isAllowed) this.actionActionList.push(this.actionMap[key]);
          }
          break;
        case 'FIELD_EDIT_VALIDATOR': //@TODO: unknown logic (maybe make an @output and connect it to both the page/form base classes that will handle the cases)
          if (this.actionMap[key]) {
            const isAllowed = await this.checkActionPermissions(this.actionMap[key]);
            if (isAllowed) this.fieldEditValidatorActionList.push(this.actionMap[key]);
          }
          break;
        default:
          break;
      }
    });

    // Wait for all permission check promises to resolve
    await Promise.all(permissionCheckPromises);

    this.stateMachineEventActionList = [...this.stateMachineEventActionList];
    this.actionActionList = [...this.actionActionList];
    this.fieldEditValidatorActionList = [...this.fieldEditValidatorActionList];
    this.allActionsList = [];
    if (this.stateMachineEventActionList.length > 0) {
      this.allActionsList.push({ id: 1, label: 'Workflow Actions:', items: [...this.stateMachineEventActionList] });
    }
    if (this.actionActionList.length > 0) {
      this.allActionsList.push({ id: 2, label: 'Other Actions', items: [...this.actionActionList] });
    }
    // if(this.fieldEditValidatorActionList.length > 0){ //@TODO: i'm not sure if this should have actions
    //     this.allActionsList.push({id:3,label:'Field Validator Actions',items:[...this.fieldEditValidatorActionList]});
    // }
    this.allActionsList = [...this.allActionsList];
  }
  async checkActionPermissions(action: IAction) {
    if (isNullObj(action?.permission)) return true;
    const isAllowed = await this.permissionService.hasPermission(action.permission);
    return isAllowed;
  }
}
interface Config {
  requestService: BaseRequestControllerWithRuleService<any, any>;
  itemId: string;
  ruleType?: FlatRule.RuleTypeEnum;
}
