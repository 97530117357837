import { Component, Input, OnInit } from '@angular/core';
import { BaseViewItem } from '@shared/classes/view/BaseViewItem';

@Component({
  selector: 'app-html-text-view',
  templateUrl: './html-text-view.component.html',
  styleUrls: ['./html-text-view.component.scss'],
})
export class HtmlTextViewComponent extends BaseViewItem implements OnInit {
  @Input() filter: string;

  constructor() {
    super();
  }

  ngOnInit(): void {}
}
